import { datadogRum } from '@datadog/browser-rum-slim'
import * as Sentry from '@sentry/react'
import { MotionConfig } from 'framer-motion'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { IconContext } from 'react-icons'
import { Provider } from 'react-redux'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { install } from 'resize-observer'

import UserDataProvider from '@redux/UserDataProvider'
import { reduxStore } from '@redux/configureStore'
import App from 'components/App'
import ApplicationModals from 'components/ApplicationModals'
import ErrorBoundary from 'components/ErrorBoundary'
import HelpScoutBeacon from 'components/layout/HelpScoutBeacon'
import { viewportHeightCorrection } from 'helpers/display'
import Env, { isDevelopment } from 'helpers/environment'
import { initI18n } from 'helpers/i18nInit'
import { convertStringifiedBoolean } from 'helpers/utils'
import { identifyUser } from 'services/Analytics'

if (!window.ResizeObserver) install()

Sentry.init({
  dsn: Env.PUBLIC_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: Number(Env.PUBLIC_SENTRY_SAMPLING_RATE) ?? 1.0,
  environment: Env.REACT_ENV,
  enabled: isDevelopment === false,
})

if (Env.PUBLIC_DATADOG_APPLICATION_ID && Env.PUBLIC_DATADOG_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: Env.PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: Env.PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: Env.PUBLIC_DATADOG_SERVICE,
    env: Env.REACT_ENV,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
    telemetrySampleRate: 0,
  })
}

initI18n()

document.addEventListener('DOMContentLoaded', () => {
  viewportHeightCorrection()

  const appContainer = document.getElementById('appContainer')
  const userId = appContainer.getAttribute('data-userid')
  const userRoles = JSON.parse(appContainer.getAttribute('data-roles'))
  const userIsVerified = convertStringifiedBoolean(appContainer.getAttribute('data-isverified'))
  const userRating = appContainer.getAttribute('data-userrating')

  if (userId) {
    identifyUser(userId)
    window.userId = Number(userId)
  }

  if (appContainer) {
    const root = createRoot(appContainer)
    root.render(
      <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
        <Provider store={reduxStore}>
          <UserDataProvider
            userId={userId}
            userRoles={userRoles}
            userIsVerified={userIsVerified}
            userRating={userRating}
          >
            <StrictMode>
              <MotionConfig reducedMotion="user">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <App />
                </IconContext.Provider>
              </MotionConfig>
            </StrictMode>
            <div id="reactModalsContainer">
              <ApplicationModals />
            </div>
          </UserDataProvider>
        </Provider>
        <HelpScoutBeacon />
      </Sentry.ErrorBoundary>,
    )
  }
})
