import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import completeInduction from '@redux/thunks/inductions/completeInduction';
import AnimatedWizard from 'components/Inductions/AnimatedWizard';
import ProgressBullets from 'components/Inductions/InductionQuiz/ProgressBullets';
import InductionQuizButtons from 'components/Inductions/InductionQuiz/QuizButtons';
import InductionQuizQuestion from 'components/Inductions/InductionQuiz/QuizQuestion';
import InductionQuizResults from 'components/Inductions/InductionQuiz/QuizResults';
import Alert from 'components/common/Alert';
import { Stack } from 'components/layout/primitives';
import { ERROR, INFO } from 'config/alert';
import Debug from 'helpers/debug';
import { checkQuizAnswers, hasYoutubeVideo } from 'helpers/inductions';
import { useWizard } from 'hooks/application';
import { trackEvent } from 'services/Analytics';
const debug = Debug('components:inductions:inductions-quiz');
const INDUCTION_QUIZ_OUTCOMES = {
    SUCCESS: 'success',
    FAIL: 'fail',
};
const InductionQuiz = ({ inductionId, isRetryLocked, isRetry, setIsRetry, setIsRewatch, setIsRetryLocked, pauseYoutubeVideo, playYoutubeVideo, }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { step, wizard } = useWizard();
    const methods = useForm({ mode: 'onChange' });
    const { getValues, control } = methods;
    const formChanged = useWatch({ control });
    const [showSelectAnAnswerError, setShowSelectAnAnswerError] = useState(false);
    const [quizCompletedSuccessfully, setQuizCompletedSuccessfully] = useState(false);
    const [answersChanged, setAnswersChanged] = useState({});
    const [correctAnswerAlert, setCorrectAnswerAlert] = useState(false);
    const [areAllAnswersCorrect, setAreAllAnswersCorrect] = useState(false);
    const inductionData = useAppSelector((state) => state.inductions.items[inductionId]);
    const questions = inductionData?.questions;
    const isInductionCompleted = inductionData?.isComplete;
    const resultsStepIndex = questions.length;
    const isResultsStep = step === resultsStepIndex;
    const hasVideo = hasYoutubeVideo(inductionData.content) === true;
    const isCurrentQuestionAnswered = () => {
        const answers = Object.values(getValues());
        const currentAnswer = answers[step];
        return currentAnswer !== null && currentAnswer !== undefined;
    };
    const paginate = (newDirection) => {
        if (newDirection === 1) {
            if (step + 1 !== questions.length) {
                if (isCurrentQuestionAnswered() === true) {
                    wizard.nextStep();
                    setCorrectAnswerAlert(false);
                    setShowSelectAnAnswerError(false);
                }
                else {
                    setShowSelectAnAnswerError(true);
                }
            }
        }
        else if (newDirection === -1) {
            wizard.previousStep();
            if (step - 1 >= 0) {
                setCorrectAnswerAlert(false);
                setShowSelectAnAnswerError(false);
            }
        }
    };
    const retryQuiz = () => {
        wizard.goToStep(0);
        setIsRetryLocked(true);
        pauseYoutubeVideo();
        setIsRetry(true);
        trackEvent('INDUCTION_QUIZ_RETRY', {
            induction_title: inductionData.title,
            induction_id: inductionId,
            button_state: 'active',
            video: hasVideo,
            refresh: isInductionCompleted,
        });
    };
    const onSubmit = async (event) => {
        if (isCurrentQuestionAnswered() === false) {
            setShowSelectAnAnswerError(true);
            event.preventDefault();
            return;
        }
        setAnswersChanged({});
        setCorrectAnswerAlert(false);
        const answers = Object.values(getValues());
        const { areAnswersCorrect, incorrectlyAnsweredQuestions } = checkQuizAnswers(questions, answers);
        setAreAllAnswersCorrect(areAnswersCorrect);
        wizard.nextStep();
        if (areAnswersCorrect === true) {
            if (isInductionCompleted === true) {
                setQuizCompletedSuccessfully(true);
                trackEvent('INDUCTION_QUIZ_SUCCESS_REFRESH', {
                    induction_name: inductionData.title,
                    induction_id: inductionData.id,
                });
            }
            else {
                const inductionCompletion = await dispatch(completeInduction(inductionId)).catch(debug);
                if (inductionCompletion.meta.requestStatus === 'fulfilled') {
                    setQuizCompletedSuccessfully(true);
                }
            }
        }
        else if (areAnswersCorrect === false) {
            setQuizCompletedSuccessfully(false);
            pauseYoutubeVideo();
            setIsRetryLocked(true);
            if (isRetry === false) {
                trackEvent('INDUCTION_QUIZ_FAIL', {
                    induction_name: inductionData.title,
                    incorrectQuestionNumbers: incorrectlyAnsweredQuestions,
                    refresh: isInductionCompleted,
                });
                setIsRewatch(true);
            }
            else if (isRetry === true) {
                trackEvent('INDUCTION_QUIZ_SUBMIT_RETRY', {
                    induction_name: inductionData.title,
                    quiz_outcome: INDUCTION_QUIZ_OUTCOMES.FAIL,
                    incorrectQuestionNumbers: incorrectlyAnsweredQuestions,
                    refresh: isInductionCompleted,
                });
            }
        }
    };
    useEffect(() => {
        setShowSelectAnAnswerError(false);
    }, [formChanged]);
    if (questions === undefined) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(FormProvider, { ...methods, children: _jsx("form", { id: "induction-quiz-form", children: _jsx("div", { className: "induction-quiz", children: _jsxs(Stack, { gap: "1.5rem", children: [isResultsStep ? (_jsx(InductionQuizResults, { questions: questions, selectedAnswers: Object.values(getValues()), hasVideo: hasVideo, inductionTitle: inductionData.title, playYoutubeVideo: playYoutubeVideo, quizCompletedSuccessfully: quizCompletedSuccessfully, areAllAnswersCorrect: areAllAnswersCorrect, retryQuiz: retryQuiz })) : (_jsxs(_Fragment, { children: [_jsx(AnimatedWizard, { step: step, paginate: paginate, children: _jsx(InductionQuizQuestion, { index: step, questionText: questions[step].body, answers: questions[step].answers, questionCount: questions.length, isInductionCompleted: isInductionCompleted, isRetry: isRetry, answersChanged: answersChanged, setAnswersChanged: setAnswersChanged, setCorrectAnswerAlert: setCorrectAnswerAlert }) }), _jsx(ProgressBullets, { current: step, total: questions.length }), correctAnswerAlert === true && (_jsx(Alert, { variant: INFO, children: t('inductions.page.show.quiz.correct_answer_alert') }))] })), showSelectAnAnswerError === true && (_jsx(Alert, { variant: ERROR, children: t('inductions.page.show.quiz.select_an_answer') }))] }) }) }) }), (isResultsStep === false || hasVideo === true) &&
                quizCompletedSuccessfully === false &&
                areAllAnswersCorrect === false && (_jsx(InductionQuizButtons, { isResultsStep: isResultsStep, paginate: paginate, onSubmit: onSubmit, step: step, questions: questions, retryQuiz: retryQuiz, isRetryLocked: isRetryLocked, inductionId: inductionData.id, inductionTitle: inductionData.title }))] }));
};
export { INDUCTION_QUIZ_OUTCOMES };
export default memo(InductionQuiz);
