import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineSchedule } from 'react-icons/md';
import { Link } from 'react-router-dom';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import StoreInfo from 'components/common/StoreInfo/StoreInfo';
import { MY_COLLECTIONS_URL } from 'config/routes';
import { formatShortDateAndTime24 } from 'helpers/time';
import EmptyStateCollectionCard from 'images/EmptyStateCollectionCard.png';
import { trackEvent } from 'services/Analytics';
import styles from './CollectionCard.module.scss';
const CollectionCard = ({ collection, index }) => {
    const { t } = useTranslation();
    const { collectionId, business, store, storeId, nextCollection } = collection || {};
    const nextCollectionDate = useMemo(() => {
        if (collection !== undefined) {
            return formatShortDateAndTime24(nextCollection, store?.timezone);
        }
    }, [collection, nextCollection, store?.timezone]);
    return (_jsx("article", { className: clsx(collection === undefined
            ? styles.noCollectionContainer
            : [styles.collectionContainer, 'card']), "data-testid": index !== undefined && `collection-card-${index}`, children: collection === undefined ? (_jsx("img", { alt: "", src: EmptyStateCollectionCard })) : (_jsxs(Link, { className: styles.cardLink, to: `${MY_COLLECTIONS_URL}/${collectionId}`, onClick: () => trackEvent('FWH_DASHBOARD_CLICK_INDIVIDUAL_COLLECTION'), children: [_jsx(StoreInfo, { logoUrl: business?.logo.url, location: store?.location ?? {}, storeId: storeId, small: true, addressLink: false }), _jsxs("div", { className: styles.collectionInfo, children: [_jsx("span", { className: styles.icon, children: _jsx(MdOutlineSchedule, {}) }), _jsx(SetInnerHtmlSafe, { children: t('dashboard-scene.your-collection-slots.collection-card.next-collection', {
                                date: `<strong>${nextCollectionDate}</strong>`,
                            }) })] })] })) }));
};
export default CollectionCard;
