import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import InfoItem, { infoItemTypes } from 'components/common/InfoItem';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { FETCHING_STATUSES } from 'config/api';
import { AVAILABLE_COLLECTIONS_URL } from 'config/routes';
import { useFetchMyCollectionsWithStoreAndBusinessInfo } from 'hooks/collections';
import NoCollectionIcon from 'images/Tomato.svg';
import HelpfulInformation from './HelpfulInformation';
import IdCard from './IdCard';
import MyCollectionCard from './MyCollectionCard';
const { IDLE, LOADING, SUCCESS } = FETCHING_STATUSES;
const { EMPTY } = infoItemTypes;
const MyCollectionCardList = () => {
    const { t } = useTranslation();
    const { sortedCollectionsWithStoreInfo, fetchingStatus: collectionsFetchingStatus } = useFetchMyCollectionsWithStoreAndBusinessInfo();
    const hasNoCollections = collectionsFetchingStatus === SUCCESS && sortedCollectionsWithStoreInfo.length === 0;
    if (collectionsFetchingStatus === IDLE || collectionsFetchingStatus === LOADING) {
        return _jsx(LoadingSpinner, { containerHeight: "400px" });
    }
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [hasNoCollections === true && (_jsxs("div", { id: "no-collections", children: [_jsx(InfoItem, { type: EMPTY, title: t('collection_slots.my-collections.title'), description1: t('collection_slots.my-collections.description1'), description2: t('collection_slots.my-collections.description2'), icon: NoCollectionIcon }), _jsx(Button, { href: `${AVAILABLE_COLLECTIONS_URL}`, children: t('join.food-waste-hero.success-action-button') })] })), _jsxs("div", { className: "info-cards", children: [_jsx("div", { className: "info", children: _jsx(IdCard, {}) }), _jsx("div", { className: "info", children: _jsx(HelpfulInformation, {}) })] }), hasNoCollections === false && (_jsx("div", { id: "your-collections", "data-testid": "collection-card-list", children: sortedCollectionsWithStoreInfo.map((collection) => {
                        if (collection.business?.logo === undefined ||
                            collection.store?.location === undefined) {
                            return null;
                        }
                        return (_jsx(MyCollectionCard, { collectionId: Number(collection.collectionId) }, collection.collectionId));
                    }) }))] }) }));
};
export default MyCollectionCardList;
