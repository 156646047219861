import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@redux/hooks';
const TargetCollectionSelection = ({ userId, nextCollection, value, groupName, isChecked, onChange, isDisabled, timezone, }) => {
    const { t, i18n } = useTranslation();
    const userName = useAppSelector((state) => state.users.items[userId]?.name);
    const nextCollectionDate = DateTime.fromISO(nextCollection);
    return (_jsx("li", { children: _jsxs("label", { children: [_jsx("input", { className: "with-gap", name: groupName, type: "radio", value: value, checked: isChecked, onChange: onChange, disabled: isDisabled }), _jsx("span", { dangerouslySetInnerHTML: {
                        __html: t('your-collections.page.show.swap-modal.choices-modal.swap-for-slot', {
                            name: userName,
                            date: DateTime.fromISO(nextCollectionDate, {
                                zone: timezone,
                                locale: i18n.language,
                            }).toFormat('EEE, dd MMM HH:mm'),
                        }),
                    } })] }) }));
};
export default TargetCollectionSelection;
