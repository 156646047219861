import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import CardWithButton from 'components/common/Cards/CardWithButton';
import SceneHeader from 'components/common/SceneHeader';
import { Grid, Stack } from 'components/layout/primitives';
import { useIsMobile } from 'hooks/application';
const FaqScene = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    return (_jsxs("div", { className: "faq-scene", children: [_jsxs("div", { className: "faq-header", children: [_jsx(SceneHeader, { title: t('faq.index.header-title'), description: t('faq.index.header-intro') }), _jsx("h3", { children: t('faq.index.card-header') })] }), _jsx(Stack, { gutter: "1rem", children: _jsxs(Grid, { columns: isMobile === true ? 1 : 3, gap: "1rem", children: [_jsx(CardWithButton, { title: t('faq.index.card.card-title'), description: t('faq.index.card.card-description'), buttonText: t('faq.index.card.card-button'), buttonLink: t('faq.index.card.card-url'), buttonSecondary: true }), _jsx(CardWithButton, { title: t('faq.index.card-spanish.card-title'), description: t('faq.index.card-spanish.card-description'), buttonText: t('faq.index.card-spanish.card-button'), buttonLink: t('faq.index.card-spanish.card-url'), buttonSecondary: true }), _jsx(CardWithButton, { title: t('faq.index.card-singapore.card-title'), description: t('faq.index.card-singapore.card-description'), buttonText: t('faq.index.card-singapore.card-button'), buttonLink: t('faq.index.card-singapore.card-url'), buttonSecondary: true })] }) }), _jsxs("div", { className: "hub-button-section", children: [_jsx("p", { children: t('faq.index.button-header') }), _jsx(Button, { href: t('faq.index.general.url'), children: t('faq.index.button-text') })] })] }));
};
export default FaqScene;
