import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@redux/hooks';
import MyAvatar from 'components/common/MyAvatar';
import IconOlioLogo from 'images/olio-logo-header.svg';
import styles from './IdCard.module.scss';
const IdCard = () => {
    const { t } = useTranslation();
    const userName = useAppSelector((state) => state.users.currentUser?.name?.firstName);
    return (_jsx("div", { className: clsx('card', styles.root), children: _jsxs("div", { className: "card-stacked", children: [_jsx("div", { className: styles.header, children: _jsx("img", { alt: "", src: IconOlioLogo, "data-testid": "logo" }) }), _jsx("div", { children: _jsxs("div", { className: styles.content, children: [_jsx(MyAvatar, { className: styles.avatar }), _jsxs("div", { className: styles.userDetails, children: [_jsx("h3", { children: userName }), _jsx("strong", { children: t('your-collections.id-card.title') }), _jsx("p", { children: t('your-collections.id-card.role') })] })] }) })] }) }));
};
export default IdCard;
