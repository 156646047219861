import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@redux/hooks';
import Note from 'components/common/Note';
import Rota from 'components/common/StoreRota/Rota';
import { useIsMobile } from 'hooks/application';
const CollectionDetails = ({ collectionId }) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const selectedCollection = useAppSelector((state) => state.collections?.items[collectionId]);
    const storeId = selectedCollection?.storeId;
    const collectionNotes = selectedCollection?.notes;
    const selectedStore = useAppSelector((state) => state.stores?.items[storeId]);
    const businessId = selectedStore?.businessId;
    const storeNotes = selectedStore?.notes;
    const businessNotes = useAppSelector((state) => state.stores?.businesses[businessId])?.notes;
    const hasNotes = Boolean(collectionNotes || storeNotes || businessNotes);
    return (_jsxs("div", { className: "collection-details", children: [_jsxs("div", { className: hasNotes === true ? 'notes-container' : 'notes-container no-border-mobile', children: [collectionNotes !== undefined && collectionNotes !== '' && (_jsx(Note, { title: t('your-collections.page.show.details.notes.collection-notes'), note: _jsx("span", { dangerouslySetInnerHTML: { __html: collectionNotes } }), className: "note-wrapper" })), isMobile === true && (_jsx(Rota, { storeId: storeId, className: "rota-wrapper", timezone: selectedStore?.timezone })), storeNotes !== undefined && storeNotes !== '' && (_jsx(Note, { title: t('your-collections.page.show.details.notes.store-notes'), note: _jsx("span", { dangerouslySetInnerHTML: { __html: storeNotes } }), className: "note-wrapper" })), businessNotes !== undefined && businessNotes !== '' && (_jsx(Note, { title: t('your-collections.page.show.details.notes.business-notes'), note: _jsx("span", { dangerouslySetInnerHTML: { __html: businessNotes } }), className: "note-wrapper business-notes" }))] }), isMobile === false && (_jsx(Rota, { storeId: storeId, className: "rota-wrapper", timezone: selectedStore?.timezone }))] }));
};
export default CollectionDetails;
