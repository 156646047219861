import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '@redux/hooks';
import toggleSidebar from '@redux/thunks/application/toggleSidebar';
import { getSidebarLinks } from 'config/routes';
import { useIsMobile } from 'hooks/application';
import { trackEvent } from 'services/Analytics';
import styles from './Link.module.scss';
const SidebarLink = ({ icon, type, url, target = '_self', trackingEvent }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    const isMobile = useIsMobile();
    const sidebarLinks = useMemo(() => {
        return getSidebarLinks(t);
    }, []);
    const configUrl = sidebarLinks[type].url;
    const linkUrl = url ?? configUrl;
    const handleClick = () => {
        const eventName = trackingEvent || `NAVIGATE_${type.toUpperCase()}`;
        trackEvent(eventName, {
            origin: 'menu',
        });
        if (isMobile) {
            dispatch(toggleSidebar());
        }
    };
    return (_jsx(Link, { to: linkUrl, target: target, onClick: handleClick, role: "link", children: _jsx("li", { className: clsx({ [styles.active]: currentPath?.includes(configUrl) === true }), children: _jsxs("span", { className: styles.iconAndTitle, children: [_jsx("span", { className: styles.icon, "data-testid": "sidebarIcon", children: icon }), _jsx("span", { className: styles.link, "data-testid": "sidenav-link", children: sidebarLinks[type].text })] }) }) }));
};
export default SidebarLink;
