import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { COLLECTIONS_FETCHING_STATUS_SET_IDLE } from '@redux/actionTypes';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import Button from 'components/common/Button';
import Card from 'components/common/Cards/Card';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { FETCHING_STATUSES } from 'config/api';
import { AVAILABLE_COLLECTIONS_URL, MY_COLLECTIONS_URL } from 'config/routes';
import { useFetchMyCollectionsWithStoreAndBusinessInfo } from 'hooks/collections';
import { trackEvent } from 'services/Analytics';
import CollectionCard from './CollectionCard';
import styles from './YourCollectionSlotsWidget.module.scss';
const { IDLE, LOADING, SUCCESS } = FETCHING_STATUSES;
const YourCollectionSlotsWidget = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { sortedCollectionsWithStoreInfo, fetchingStatus: collectionsFetchingStatus } = useFetchMyCollectionsWithStoreAndBusinessInfo();
    const { storesFetchingStatus, businessesFetchingStatus } = useAppSelector((state) => state.stores);
    const noCollections = useMemo(() => {
        return collectionsFetchingStatus === SUCCESS && sortedCollectionsWithStoreInfo.length === 0;
    }, [collectionsFetchingStatus, sortedCollectionsWithStoreInfo]);
    const showSpinner = useMemo(() => {
        return (collectionsFetchingStatus === IDLE ||
            collectionsFetchingStatus === LOADING ||
            storesFetchingStatus === IDLE ||
            storesFetchingStatus === LOADING ||
            businessesFetchingStatus === IDLE ||
            businessesFetchingStatus === LOADING);
    }, [collectionsFetchingStatus, storesFetchingStatus, businessesFetchingStatus]);
    const slicedCollectionsWithStoreInfo = useMemo(() => {
        if (sortedCollectionsWithStoreInfo.length > 3) {
            return sortedCollectionsWithStoreInfo.slice(0, 3);
        }
        return sortedCollectionsWithStoreInfo;
    }, [sortedCollectionsWithStoreInfo]);
    const handleRedirectToAvailableCollections = () => {
        dispatch({ type: COLLECTIONS_FETCHING_STATUS_SET_IDLE });
        trackEvent('FWH_DASHBOARD_VIEW_AVAILABLE_COLLECTIONS');
    };
    return (_jsxs(Card, { className: styles.card, children: [_jsx("div", { className: styles.headingWrapper, children: _jsx(Card.Heading, { children: t('dashboard-scene.your-collection-slots.header') }) }), showSpinner === true && noCollections === false && (_jsx(LoadingSpinner, { containerHeight: "100px" })), _jsxs(motion.div, { layout: true, transition: {
                    layout: { duration: 0.2 },
                }, children: [showSpinner === false && noCollections === false && (_jsxs(_Fragment, { children: [slicedCollectionsWithStoreInfo.map((collection, index) => {
                                return (_jsx(CollectionCard, { collection: collection, index: index }, collection.collectionId));
                            }), _jsx(Link, { to: MY_COLLECTIONS_URL, children: _jsx(Button, { className: styles.cardButton, onClick: () => trackEvent('FWH_DASHBOARD_VIEW_MY_COLLECTIONS'), children: t('dashboard-scene.your-collection-slots.button-my-collections') }) })] })), noCollections === true && (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.noSlotsTitle, children: t('dashboard-scene.your-collection-slots.no-slots-header') }), _jsx(CollectionCard, { collection: undefined }), _jsx("p", { className: styles.noSlotsDescription, children: t('dashboard-scene.your-collection-slots.no-slots-description') }), _jsx(Link, { to: AVAILABLE_COLLECTIONS_URL, children: _jsx(Button, { className: styles.cardButton, onClick: handleRedirectToAvailableCollections, children: t('dashboard-scene.your-collection-slots.button-available-collections') }) })] }))] })] }));
};
export default YourCollectionSlotsWidget;
