import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdHowToReg } from 'react-icons/md';
import Button from 'components/common/Button';
import { modalKeys } from 'config/modals';
import { useModal } from 'hooks/application';
const { CLOSE_BASKET_CONFIRMATION } = modalKeys;
const Footer = ({ basketId }) => {
    const { t } = useTranslation();
    const { openModal } = useModal(CLOSE_BASKET_CONFIRMATION);
    return (_jsx(Button, { className: "mark-collected-btn", leftContent: _jsx(MdHowToReg, {}), onClick: () => openModal({ basketId }), children: t('baskets.collected') }));
};
export default memo(Footer);
