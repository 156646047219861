import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAppSelector } from '@redux/hooks';
import { getMapUrl } from 'helpers/map';
import Address from '../Address';
const StoreInfo = ({ storeId, logoUrl, location, small = false, addressLink = true }) => {
    const mapUrl = getMapUrl(location);
    const { name: storeName, address, businessId, } = useAppSelector((state) => state.stores.items[storeId] ?? {});
    const { name: businessName } = useAppSelector((state) => state.stores.businesses[businessId] ?? {});
    return (_jsxs("div", { className: clsx('store-info-container', small === true && 'small'), children: [_jsx("div", { className: "store-logo", children: _jsx("img", { src: logoUrl, alt: `${businessName} logo` }) }), _jsxs("div", { className: "store-info", children: [_jsxs("h3", { className: "store-name", children: [_jsx("span", { children: businessName }), " | ", _jsx("span", { children: storeName })] }), addressLink === true ? (_jsx("a", { href: mapUrl, target: "_blank", rel: "noreferrer", children: _jsx(Address, { address: address }) })) : (_jsx(Address, { address: address }))] })] }));
};
StoreInfo.propTypes = {
    storeId: PropTypes.number.isRequired,
    logoUrl: PropTypes.string.isRequired,
    location: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
    }),
    small: PropTypes.bool,
    addressLink: PropTypes.bool,
};
export default StoreInfo;
