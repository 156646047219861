import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import MyVolunteerActivityWidget from 'components/Dashboard/MyVolunteerActivityWidget';
import UsersNearMeWidget from 'components/Dashboard/UsersNearMeWidget';
import YourCollectionSlotsWidget from 'components/Dashboard/YourCollectionSlotsWidget';
import CardWithButton from 'components/common/Cards/CardWithButton';
import DismissableCard from 'components/common/Cards/DismissableCard';
import SceneHeader from 'components/common/SceneHeader';
import { FETCHING_STATUSES } from 'config/api';
import { REGISTRATION_SUCCESS } from 'config/registration';
import { AVAILABLE_COLLECTIONS_URL } from 'config/routes';
import { AMBASSADOR_WIDGET_PREFERENCE_KEY } from 'config/user';
import Debug from 'helpers/debug';
import { useIsMobile } from 'hooks/application';
import { usePreference } from 'hooks/preferences';
import happyFruits from 'images/happyFruits.webp';
import { trackEvent } from 'services/Analytics';
import styles from './DashboardScene.module.scss';
const { SUCCESS } = FETCHING_STATUSES;
const debug = Debug('scenes:dashboard-scene');
const DashboardScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const [searchParams] = useSearchParams();
    const registrationSuccess = searchParams.get(REGISTRATION_SUCCESS);
    const name = useAppSelector((state) => state.users.currentUser?.name?.firstName ?? '');
    const { preference: hideAmbassadorWidget, preferencesFetchingStatus } = usePreference(AMBASSADOR_WIDGET_PREFERENCE_KEY);
    useEffect(() => {
        dispatch(fetchUserPreferences()).catch(debug);
    }, []);
    return (_jsxs("div", { id: "dashboard-scene", className: styles.dashboardScene, children: [_jsx(SceneHeader, { title: t('dashboard-scene.title', { name }), description: t('dashboard-scene.description') }), registrationSuccess === 'true' && (_jsx(CardWithButton, { title: t('dashboard-scene.welcome-card.header'), description: t('dashboard-scene.welcome-card.description1'), description2: t('dashboard-scene.welcome-card.description2'), buttonText: t('dashboard-scene.welcome-card.button'), buttonLink: AVAILABLE_COLLECTIONS_URL, className: styles.welcomeCard })), _jsxs(Masonry, { breakpointCols: isMobile === true ? 1 : 2, className: clsx(styles.widgetContainer), columnClassName: styles.column, children: [_jsx(YourCollectionSlotsWidget, {}), _jsx(MyVolunteerActivityWidget, {}), hideAmbassadorWidget === false && preferencesFetchingStatus === SUCCESS && (_jsx(DismissableCard, { heading: t('dashboard-scene.ambassador-card.heading'), image: happyFruits, buttonText: t('dashboard-scene.ambassador-card.button'), buttonLink: t('external-urls.squad-starter-link'), userPreferenceToUpdate: AMBASSADOR_WIDGET_PREFERENCE_KEY, handleDismissClick: () => trackEvent('FWH_DASHBOARD_DISMISS_AMBASSADOR'), handleActionClick: () => trackEvent('FWH_DASHBOARD_BECOME_AMBASSADOR'), children: t('dashboard-scene.ambassador-card.body') })), _jsx(UsersNearMeWidget, {})] })] }));
};
export default DashboardScene;
