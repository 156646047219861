import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUser from '@redux/thunks/users/fetchUser';
import UserAvatar from 'components/common/UserAvatar';
import Debug from 'helpers/debug';
const debug = Debug('components:basket:requester:index');
const Requester = ({ basketId }) => {
    const userId = useAppSelector((state) => state?.baskets.items[basketId]?.requesterId);
    const user = useAppSelector((state) => state?.users.items[userId]);
    const name = user?.name;
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (undefined === name) {
            dispatch(fetchUser(userId)).catch(debug);
        }
    }, []);
    return (_jsxs("div", { className: "icon-wrapper", children: [_jsx(UserAvatar, { userId: userId }), _jsx("h3", { children: name })] }));
};
export default Requester;
