import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import VideoDuration from 'components/Inductions/AdminContent/VideoDuration';
import Debug from 'helpers/debug';
import { extractYoutubeVideoIdFromUrl, hasYoutubeVideo } from 'helpers/inductions';
import { trackEvent } from 'services/Analytics';
const debug = Debug('components:inductions:admin-content');
const INJECTED_ID_PREFIX = 'youtube-video-';
const videoOptions = {
    height: '315',
    width: '560',
};
const youTubeStates = {
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
};
const injectCalendlyScript = () => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
};
const AdminContent = forwardRef(({ content, inductionId, inductionTitle, setIsRetryLocked, isRewatch }, ref) => {
    const { t } = useTranslation();
    const contentRef = useRef(null);
    const [videoDurationInSeconds, setVideoDurationInSeconds] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    const handleYouTubeStateChange = (youTubeState) => {
        switch (youTubeState) {
            case youTubeStates.PLAYING:
                if (isPlaying === false) {
                    setIsPlaying(true);
                }
                break;
            case youTubeStates.ENDED:
            case youTubeStates.PAUSED:
                setIsPlaying(false);
                break;
            default:
                break;
        }
    };
    const replaceYoutubeVideo = (iframe) => {
        if (hasYoutubeVideo(content) === true) {
            const videoId = extractYoutubeVideoIdFromUrl(iframe.src);
            const injectedElementId = `${INJECTED_ID_PREFIX}${videoId}`;
            iframe.insertAdjacentHTML('afterend', `<div id='${injectedElementId}'/>`);
            debug(`Replacing player for ${iframe.src}`);
            const root = createRoot(document.getElementById(injectedElementId));
            root.render(_jsxs(_Fragment, { children: [_jsx("p", { className: "video-instruction", children: t('inductions.page.show.video_instruction') }), _jsx(YouTube, { videoId: videoId, opts: videoOptions, onReady: (event) => {
                            setVideoDurationInSeconds(event.target.getDuration());
                            ref.current = event.target;
                        }, onStateChange: (state) => {
                            handleYouTubeStateChange(state.data);
                        } })] }));
            iframe.parentNode.removeChild(iframe);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const element = findDOMNode(contentRef.current);
        if (element instanceof Element) {
            const iframes = element.querySelectorAll('iframe');
            if (iframes) {
                iframes.forEach((iframe) => {
                    replaceYoutubeVideo(iframe);
                });
            }
        }
    }, []);
    useEffect(() => {
        if (isPlaying === true) {
            trackEvent('INDUCTION_VIDEO_PLAY', {
                induction_id: inductionId,
                induction_title: inductionTitle,
                rewatch: isRewatch,
            });
            if (isRewatch === true) {
                setIsRetryLocked(false);
            }
        }
    }, [isPlaying]);
    useEffect(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const calendlyNode = findDOMNode(contentRef.current)?.querySelector('.calendly-inline-widget');
        if (calendlyNode) {
            debug('Setting up Calendly');
            injectCalendlyScript();
        }
    });
    return (_jsxs("div", { className: "induction-content-wrapper", children: [videoDurationInSeconds !== undefined && (_jsx(VideoDuration, { durationInSeconds: videoDurationInSeconds })), content !== undefined && (_jsx("div", { className: "induction-content", ref: contentRef, dangerouslySetInnerHTML: { __html: content } }))] }));
});
export { INJECTED_ID_PREFIX, youTubeStates };
AdminContent.displayName = 'AdminContent';
export default AdminContent;
