import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineLock } from 'react-icons/md';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { INFO } from 'config/alert';
import { trackEvent } from 'services/Analytics';
const InductionQuizButtons = ({ isResultsStep, paginate, onSubmit, step, questions, retryQuiz, isRetryLocked, inductionId, inductionTitle, }) => {
    const { t } = useTranslation();
    const [quizRetryAttempted, setQuizRetryAttempted] = useState(false);
    const handleRetry = () => {
        setQuizRetryAttempted(false);
        retryQuiz();
    };
    const handleRetryAttempt = () => {
        setQuizRetryAttempted(true);
        trackEvent('INDUCTION_QUIZ_RETRY', {
            induction_title: inductionTitle,
            induction_id: inductionId,
            button_state: 'inactive',
            video: true,
        });
    };
    if (isResultsStep === true) {
        return (_jsx("div", { className: "induction-retry-quiz", children: isRetryLocked === true ? (_jsxs(_Fragment, { children: [quizRetryAttempted === true && (_jsx("div", { className: "alert-info-message", children: _jsx(Alert, { variant: INFO, children: t('inductions.page.show.quiz.play_video_instruction') }) })), _jsx(Button, { className: "try-quiz-again", secondary: true, onClick: handleRetryAttempt, leftContent: _jsx(MdOutlineLock, {}), children: t('inductions.page.show.quiz.try_quiz_again') })] })) : (_jsx(Button, { className: "try-quiz-again", secondary: true, onClick: handleRetry, children: t('inductions.page.show.quiz.try_quiz_again') })) }));
    }
    return (_jsxs("div", { className: "induction-quiz-buttons", children: [_jsx(Button, { className: clsx({ 'hide-button': step <= 0 }), secondary: true, onClick: () => paginate(-1), children: t('inductions.page.show.quiz.previous') }), step + 1 === questions.length ? (_jsx(Button, { className: "check-answers-button", onClick: onSubmit, children: t('inductions.page.show.quiz.button-submit') })) : (_jsx(Button, { onClick: () => paginate(1), children: t('inductions.page.show.quiz.next') }))] }));
};
export default InductionQuizButtons;
