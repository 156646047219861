import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@redux/hooks';
import LoginForm from 'components/Login/LoginForm';
import Alert from 'components/common/Alert';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { ERROR, STATUS } from 'config/alert';
import { LOGIN_TYPES } from 'config/loginTypes';
import * as routes from 'config/routes';
const { PASSWORD, MAGIC } = LOGIN_TYPES;
const LoginScene = ({ loginType }) => {
    const { t } = useTranslation();
    const buttonHref = loginType === PASSWORD ? routes.LOGIN_MAGIC_URL : routes.LOGIN_URL;
    const authenticationError = useAppSelector((state) => state.users?.authenticationError);
    const [params] = useSearchParams();
    const hasLoggedOut = params.get('logout-success') === 'true';
    return (_jsx("div", { className: "login-scene row full-width", children: _jsx("div", { className: "card col s12 l6", children: _jsxs("div", { className: "card-content", children: [_jsxs("div", { className: "card-header-section", children: [hasLoggedOut === true && (_jsxs(Alert, { variant: STATUS, children: [_jsxs("strong", { children: [t('common.alerts.status'), " "] }), t('login.logout_successful')] })), _jsx("h2", { children: t('login.header.title') }), _jsx("p", { children: t('login.header.description') }), (authenticationError === PASSWORD || authenticationError === MAGIC) && (_jsx(Alert, { variant: ERROR, children: authenticationError === PASSWORD ? (_jsx(SetInnerHtmlSafe, { children: t('login-scene.attempt-login.error-with-prefix', {
                                        'error-incorrect-prefix': `<b>${t('common.alerts.error-incorrect-prefix')}</b>`,
                                    }) })) : (_jsx(SetInnerHtmlSafe, { children: t('login-scene.attempt-magic-login.error-with-prefix', {
                                        'error-prefix': `<b>${t('common.alerts.error-prefix')}</b>`,
                                    }) })) }))] }), _jsx(LoginForm, { loginType: loginType }), _jsx("div", { className: "alternative-login", children: _jsx(Link, { to: buttonHref, role: "button", children: loginType === PASSWORD
                                ? t('login.landing.password.labels.alternative')
                                : t('login.landing.magic.labels.alternative') }) })] }) }) }));
};
export default LoginScene;
