import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MdOutlinePlace } from 'react-icons/md';
import { useAppSelector } from '@redux/hooks';
import Card from 'components/common/Cards/Card';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Pill, { VARIANTS } from 'components/common/Pill';
import InnerCard from './InnerCard';
import styles from './UsersNearMeWidget.module.scss';
const NEARBY_USERS_RANGE_KM = 2;
const { PINK } = VARIANTS;
const UsersNearMeWidget = () => {
    const { t } = useTranslation();
    const nearbyUsersCount = useAppSelector((state) => state.users.currentUser?.nearbyUserCount);
    return (_jsx(motion.div, { layout: true, transition: {
            layout: { duration: 0.2 },
        }, children: _jsxs(Card, { className: styles.card, children: [_jsxs("div", { className: styles.headingSection, children: [_jsx(Card.Heading, { children: t('dashboard-scene.users-near-me.heading') }), _jsxs(Pill, { variant: PINK, children: [_jsx(Pill.Icon, { children: _jsx(MdOutlinePlace, { className: styles.icon }) }), _jsx(Pill.Text, { children: t('dashboard-scene.users-near-me.pillText', {
                                        distance: NEARBY_USERS_RANGE_KM,
                                    }) })] })] }), _jsx(Card.TextSection, { children: t('dashboard-scene.users-near-me.description') }), nearbyUsersCount === undefined || nearbyUsersCount === 0 ? (_jsx(LoadingSpinner, { containerHeight: "100px" })) : (_jsx(InnerCard, { nearbyUsersCount: nearbyUsersCount }))] }) }));
};
export default UsersNearMeWidget;
