import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineCheckCircle } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'components/common/Button';
import * as routes from 'config/routes';
import { trackEvent } from 'services/Analytics';
const InductionCard = ({ title, description, isComplete, inductionId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleClick = () => {
        trackEvent('INDUCTION_START', {
            induction_name: title,
            induction_id: inductionId,
            refresh: isComplete,
        });
        if (isComplete) {
            trackEvent('REVIEW_INDUCTION', { induction_name: title, induction_id: inductionId });
        }
        navigate(`${routes.INDUCTIONS_URL}/${inductionId}`, { state: { origin: pathname } });
    };
    return (_jsx("div", { className: "card induction-card", children: _jsxs("div", { className: "card-content", children: [_jsxs("div", { children: [_jsx("h3", { className: "induction-title", children: title }), _jsx("p", { className: "induction-description", children: description })] }), _jsx("span", { children: _jsxs("div", { className: "induction-actions", children: [_jsx(Button, { onClick: handleClick, secondary: true, children: isComplete === true
                                    ? t('inductions.induction.action.view')
                                    : t('inductions.induction.action.start') }), isComplete === true && (_jsxs("div", { className: "is-complete", children: [_jsx(MdOutlineCheckCircle, {}), _jsx("span", { className: "completed-text", children: t('inductions.induction.action.completed') })] }))] }) })] }) }));
};
export default InductionCard;
