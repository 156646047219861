import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import { COLLECTION_TYPES } from 'config/collections';
import { modalKeys } from 'config/modals';
import { useModal } from 'hooks/application';
import styles from './CollectionActions.module.scss';
import PauseModal from './PauseModal';
import SkipModal from './SkipModal';
import SwapModal from './SwapModal';
import UnassignModal from './UnassignModal';
const CollectionActions = ({ collectionId, collectionType, collectionEndDate }) => {
    const { t } = useTranslation();
    const { openModal: openUnassignModal } = useModal(modalKeys.UNASSIGN_COLLECTION);
    const { openModal: openSkipModal } = useModal(modalKeys.SKIP_COLLECTION);
    const { openModal: openPauseModal } = useModal(modalKeys.PAUSE_COLLECTION);
    const { openModal: openSwapModal } = useModal(modalKeys.SWAP_COLLECTION);
    const isOngoingWithoutEndDate = useMemo(() => {
        return collectionType === COLLECTION_TYPES.ONGOING && collectionEndDate === undefined;
    }, [collectionType, collectionEndDate]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.collectionActions, children: [isOngoingWithoutEndDate !== true ? (_jsx(Button, { className: styles.adhocButton, secondary: true, onClick: () => openUnassignModal(), children: t('your_collections.show.buttons.unassign_adhoc') })) : (_jsx(Button, { secondary: true, onClick: () => openUnassignModal(), children: t('your_collections.show.buttons.unassign') })), isOngoingWithoutEndDate === true && (_jsxs(_Fragment, { children: [_jsx(Button, { secondary: true, onClick: () => openSkipModal(), children: t('your_collections.show.buttons.skip') }), _jsx(Button, { secondary: true, onClick: () => openPauseModal(), children: t('your_collections.show.buttons.pause') }), _jsx(Button, { secondary: true, onClick: () => openSwapModal(), children: t('your_collections.show.buttons.swap') })] }))] }), _jsx(UnassignModal, { collectionId: collectionId }), _jsx(SkipModal, { collectionId: collectionId }), _jsx(PauseModal, { collectionId: collectionId }), _jsx(SwapModal, { collectionId: collectionId })] }));
};
export default memo(CollectionActions);
