import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@redux/hooks';
import Alert from 'components/common/Alert';
import { ModalTitle } from 'components/common/Modal/ConfirmationModal';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { getCollectionActionDetails } from 'helpers/collections';
import { translateApiErrors } from 'helpers/errors';
import Footer from './Footer';
import Step from './Step';
const Options = ({ mode }) => {
    const { t } = useTranslation();
    const errors = useAppSelector((state) => state.collections[mode].errors);
    const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
        return getCollectionActionDetails(t);
    }, []);
    const stepKeys = Object.keys(COLLECTION_ACTIONS_DETAILS[mode].steps);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "collection-action-options", children: [_jsx(ModalTitle, { children: COLLECTION_ACTIONS_DETAILS[mode].optionsHeader }), _jsx("div", { className: "steps", children: stepKeys.map((stepKey, index) => (_jsx(Step, { step: stepKey, mode: mode }, index))) }), errors && (_jsx(Alert, { variant: "error", children: _jsx(SetInnerHtmlSafe, { children: translateApiErrors({ t, errors }) }) }))] }), _jsx(Footer, { mode: mode })] }));
};
export default Options;
