import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/common/Button';
import Card from 'components/common/Cards/Card';
import styles from './CardWithButton.module.scss';
const CardWithButton = ({ title, subtitle, description, description2, buttonText, buttonLink, icon, buttonSecondary = false, className, headingIcon, onClick, }) => {
    return (_jsxs(Card, { className: clsx(styles.cardWithButton, className), children: [_jsxs("div", { className: styles.cardBody, children: [_jsxs("div", { className: styles.descriptionHeader, children: [headingIcon !== undefined && _jsx("span", { className: styles.smallIcon, children: headingIcon }), _jsx("h3", { className: styles.title, children: title })] }), subtitle !== undefined && _jsx("strong", { className: styles.subtitle, children: subtitle }), _jsxs("div", { className: styles.descriptionContainer, children: [_jsxs("div", { children: [_jsx("p", { className: "description-1", children: description }), description2 !== undefined && _jsx("p", { className: styles.description2, children: description2 })] }), icon !== undefined && (_jsx("div", { className: styles.cardIcon, children: _jsx("img", { alt: "", src: icon }) }))] })] }), _jsx(Link, { "data-testid": "cardWithButtonLink", to: buttonLink, children: _jsx(Button, { secondary: buttonSecondary, onClick: onClick, children: buttonText }) })] }));
};
CardWithButton.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    description2: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    icon: PropTypes.string,
    buttonSecondary: PropTypes.bool,
    className: PropTypes.string,
    headingIcon: PropTypes.node,
    onClick: PropTypes.func,
};
export default CardWithButton;
