import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import setActiveStep from '@redux/thunks/collections/setActiveStep';
import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions';
import useFetchMyStoreCollections from 'components/Collections/CollectionActions/hooks/useFetchMyStoreCollections';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { COLLECTION_ACTIONS, STEP_COLLECTION, STEP_DATE, STEP_INCREMENT, STEP_TARGET_COLLECTION, formatCollectionFullDate, getCollectionActionDetails, getCollectionTypeTranslation, getSwappableCollections, } from 'helpers/collections';
import Debug from 'helpers/debug';
import StepContentSelection from './StepContentSelection';
import TargetCollectionSelection from './TargetCollectionSelection';
const EFFECTIVE_IMMEDIATELY = 'immediate';
const { UNASSIGN, SKIP, PAUSE, SWAP } = COLLECTION_ACTIONS;
const StepContent = ({ step, mode }) => {
    const debug = Debug(`javascript:components:collection-${mode}:options:step:step-content`);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
        return getCollectionActionDetails(t);
    }, []);
    const { selectedDate, selectedCollectionId, step: activeStep, selectedIncrement, swapForCollectionId, selectedStoreId, } = useAppSelector((state) => state.collections[mode]);
    const { selectedStoreCollections, selectedStoreTimezone } = useFetchMyStoreCollections({
        onlyOngoing: mode !== UNASSIGN,
        selectedStoreId,
    });
    const storeCollectionIds = useAppSelector((state) => state.stores?.items[selectedStoreId]?.collections);
    const allCollections = useAppSelector((state) => state.collections?.items);
    const selectedStoreAllCollections = useMemo(() => {
        if (storeCollectionIds !== undefined && allCollections !== undefined) {
            return storeCollectionIds.map((collectionId) => ({
                ...allCollections[collectionId],
                id: collectionId,
            }));
        }
    }, [storeCollectionIds, allCollections]);
    const { nextCollection: selectedCollectionDate, type, validEndDates, } = useAppSelector((state) => state.collections?.items[selectedCollectionId] ?? {}, shallowEqual);
    const swapForCollection = useAppSelector((state) => state.collections?.items[swapForCollectionId] ?? {}, shallowEqual);
    const activeStepPosition = COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position;
    const isActiveStep = (step) => {
        return activeStepPosition === COLLECTION_ACTIONS_DETAILS[mode].steps[step].position;
    };
    const getCollectionText = ({ collectionType, collectionDate }) => {
        const collectionTextData = {
            type: getCollectionTypeTranslation({ t, type: collectionType }),
            date: DateTime.fromISO(collectionDate, {
                zone: selectedStoreTimezone,
                locale: i18n.language,
            }).toFormat('dd MMM, HH:mm'),
            split: '',
        };
        switch (mode) {
            case UNASSIGN:
                return t('your-collections.page.show.end-modal.choices-modal.slot', collectionTextData);
            case SKIP:
                return t('your-collections.page.show.skip-modal.choices-modal.slot', collectionTextData);
            case PAUSE:
                return t('your-collections.page.show.pause-modal.choices-modal.slot', collectionTextData);
            case SWAP:
                return t('your-collections.page.show.swap-modal.choices-modal.slot', collectionTextData);
            default:
                return undefined;
        }
    };
    const slotList = () => {
        return selectedStoreCollections.map((option, index) => (_jsx(StepContentSelection, { groupName: `${STEP_COLLECTION}-${mode}`, value: option?.collectionId, isChecked: selectedCollectionId === option?.collectionId, onChange: updateSelectedCollectionId, displayComponent: _jsx("span", { children: getCollectionText({
                    collectionType: option.type,
                    collectionDate: option?.nextCollection,
                }) }) }, index)));
    };
    const inactiveStepSlotContent = () => (_jsx(StepContentSelection, { groupName: `${STEP_COLLECTION}-${mode}`, value: selectedCollectionDate, isChecked: true, isDisabled: true, displayComponent: _jsx("span", { children: getCollectionText({
                collectionType: type,
                collectionDate: selectedCollectionDate,
            }) }) }));
    const inactiveStepDateContent = () => {
        const isValidDate = DateTime.fromISO(selectedDate).isValid;
        if (selectedDate === undefined) {
            return null;
        }
        return (_jsx(StepContentSelection, { groupName: `${STEP_DATE}-${mode}`, value: selectedDate, isChecked: true, isDisabled: true, displayComponent: _jsx(SetInnerHtmlSafe, { children: isValidDate
                    ? formatCollectionFullDate({ date: selectedDate, timezone: selectedStoreTimezone })
                    : COLLECTION_ACTIONS_DETAILS[mode].steps[STEP_DATE].immediateEffectText }) }));
    };
    const inactiveStepIncrementContent = () => {
        if (selectedIncrement === undefined) {
            return null;
        }
        return (_jsx(StepContentSelection, { groupName: `${STEP_INCREMENT}-${mode}`, value: selectedIncrement, isChecked: true, isDisabled: true, displayComponent: _jsx("span", { children: selectedIncrement }) }));
    };
    const inactiveStepTargetCollection = () => {
        if (swapForCollectionId === undefined) {
            return null;
        }
        return (_jsx(TargetCollectionSelection, { groupName: `${STEP_TARGET_COLLECTION}-${mode}`, value: swapForCollection.id, isChecked: true, isDisabled: true, onChange: updateTargetCollectionId, nextCollection: selectedCollectionDate, userId: swapForCollection.userId, timezone: selectedStoreTimezone }, swapForCollectionId));
    };
    const immediateOption = () => (_jsx(StepContentSelection, { groupName: `${STEP_COLLECTION}-${mode}`, value: EFFECTIVE_IMMEDIATELY, isChecked: selectedDate === EFFECTIVE_IMMEDIATELY, onChange: updateSelectedDate, displayComponent: _jsx("span", { dangerouslySetInnerHTML: {
                __html: COLLECTION_ACTIONS_DETAILS[mode].steps[step].immediateEffectText,
            } }) }));
    const endDateOptions = () => validEndDates.map((option, index) => {
        return (_jsx(StepContentSelection, { groupName: `${STEP_COLLECTION}-${mode}`, value: option, isChecked: selectedDate === option, onChange: updateSelectedDate, displayComponent: _jsx("span", { children: formatCollectionFullDate({ date: option, timezone: selectedStoreTimezone }) }) }, index));
    });
    const incrementList = () => {
        const increments = [1, 2, 3];
        return increments.map((increment) => {
            return (_jsx(StepContentSelection, { groupName: `${STEP_INCREMENT}-${mode}`, value: increment, isChecked: increment === selectedIncrement, onChange: updateSelectedIncrement, displayComponent: _jsx("span", { children: increment }) }, increment));
        });
    };
    const targetCollectionList = () => {
        if (selectedStoreAllCollections === undefined) {
            return null;
        }
        const swappableCollections = getSwappableCollections(selectedStoreAllCollections);
        return swappableCollections.map((targetCollection) => {
            return (_jsx(TargetCollectionSelection, { groupName: `${STEP_TARGET_COLLECTION}-${mode}`, value: targetCollection.id, userId: targetCollection.userId, isChecked: targetCollection.id === swapForCollectionId, onChange: updateTargetCollectionId, nextCollection: targetCollection.nextCollection, timezone: selectedStoreTimezone }, targetCollection.id));
        });
    };
    const updateSelectedCollectionId = (event) => {
        const collectionId = parseInt(event.target.value, 10);
        debug(`Selecting collection ${collectionId}`);
        try {
            dispatch(setSelectedOptions({
                action: mode,
                collectionId,
            }));
            if (mode === SWAP) {
                dispatch(setActiveStep({ action: mode, step: STEP_TARGET_COLLECTION }));
            }
            else {
                dispatch(setActiveStep({ action: mode, step: STEP_DATE }));
            }
        }
        catch (error) {
            debug(error);
        }
    };
    const updateSelectedDate = async (event) => {
        const date = event.target.value;
        debug(`Selecting date ${date}`);
        try {
            dispatch(setSelectedOptions({
                action: mode,
                selectedDate: date,
            }));
            if (mode === PAUSE) {
                dispatch(setActiveStep({ action: mode, step: STEP_INCREMENT }));
            }
        }
        catch (error) {
            debug(error);
        }
    };
    const updateSelectedIncrement = (event) => {
        const increment = event.target.value;
        try {
            dispatch(setSelectedOptions({
                action: mode,
                selectedIncrement: increment,
            }));
        }
        catch (error) {
            debug(error);
        }
    };
    const updateTargetCollectionId = (event) => {
        const targetCollectionId = Number(event.target.value);
        debug(`Selecting collection ${targetCollectionId}`);
        try {
            dispatch(setSelectedOptions({
                action: mode,
                swapForCollectionId: targetCollectionId,
            }));
        }
        catch (error) {
            debug(error);
        }
    };
    const getSkipDescription = () => {
        return (_jsxs(_Fragment, { children: [_jsx("p", { children: t('your-collections.page.show.skip-modal.initial-modal.description-1') }), _jsx("p", { children: t('your-collections.page.show.skip-modal.initial-modal.description-2') })] }));
    };
    return (_jsxs("div", { className: "step-content", children: [step === STEP_COLLECTION && (_jsx("form", { children: _jsx("fieldset", { children: _jsx("ul", { children: isActiveStep(STEP_COLLECTION) === true ? slotList() : inactiveStepSlotContent() }) }) })), step === STEP_DATE && (_jsx("form", { children: _jsx("fieldset", { children: _jsxs("ul", { children: [isActiveStep(STEP_DATE) === true && (mode === UNASSIGN || mode === PAUSE) && (_jsxs(_Fragment, { children: [immediateOption(), validEndDates !== undefined && endDateOptions()] })), isActiveStep(STEP_DATE) === false && inactiveStepDateContent(), mode === SKIP && getSkipDescription()] }) }) })), step === STEP_INCREMENT && (_jsxs(_Fragment, { children: [_jsx("form", { children: _jsx("fieldset", { children: _jsx("ul", { children: isActiveStep(STEP_INCREMENT) ? incrementList() : inactiveStepIncrementContent() }) }) }), _jsx("p", { children: COLLECTION_ACTIONS_DETAILS[PAUSE].steps[STEP_INCREMENT].incrementDescription })] })), step === STEP_TARGET_COLLECTION && (_jsx("form", { children: _jsx("fieldset", { children: _jsx("ul", { children: isActiveStep(STEP_TARGET_COLLECTION) === true
                            ? targetCollectionList()
                            : inactiveStepTargetCollection() }) }) }))] }));
};
export default StepContent;
