import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchCollection from '@redux/thunks/collections/fetchCollection';
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections';
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses';
import fetchStore from '@redux/thunks/stores/fetchStore';
import CollectionActions from 'components/Collections/CollectionActions';
import CollectionDates from 'components/Collections/CollectionDates';
import CollectionDetails from 'components/Collections/CollectionDetails';
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel';
import Alert from 'components/common/Alert/Alert';
import LoadingSpinner from 'components/common/LoadingSpinner';
import StoreInfo from 'components/common/StoreInfo';
import { Stack } from 'components/layout/primitives';
import { INFO } from 'config/alert';
import Debug from 'helpers/debug';
import styles from './MyCollectionWrapper.module.scss';
const debug = Debug('components:collections:my-collection-wrapper');
const MyCollectionWrapper = ({ collectionId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { storeId, type: collectionType, endDate: collectionEndDate, derestricted: isDerestrictedCollection, } = useAppSelector((state) => state.collections.items[collectionId] ?? {}, shallowEqual);
    const { businessId, location } = useAppSelector((state) => state.stores.items[storeId] ?? {}, shallowEqual);
    const { logo } = useAppSelector((state) => state.stores.businesses[businessId] ?? {}, shallowEqual);
    useEffect(() => {
        if (collectionId !== undefined) {
            dispatch(fetchCollection(collectionId)).catch(debug);
        }
    }, [collectionId]);
    useEffect(() => {
        if (storeId !== undefined) {
            dispatch(fetchStore(storeId)).catch(debug);
        }
    }, [storeId]);
    useEffect(() => {
        if (storeId !== undefined) {
            dispatch(fetchStoreCollections({ storeId })).catch(debug);
        }
    }, [storeId]);
    useEffect(() => {
        if (businessId !== undefined) {
            dispatch(fetchBusinesses([businessId])).catch(debug);
        }
    }, [businessId]);
    if (businessId === undefined || logo === undefined) {
        return _jsx(LoadingSpinner, { containerHeight: "400px" });
    }
    return (_jsxs("div", { className: clsx('card', styles.card), children: [_jsxs("div", { className: clsx('card-content', styles.content), children: [isDerestrictedCollection && _jsx(UnlimitedSlotLabel, {}), _jsx(StoreInfo, { storeId: storeId, logoUrl: logo.url, location: location }), _jsxs(Stack, { gap: "1.25rem", children: [_jsx(CollectionDates, { collectionId: collectionId }), _jsx(CollectionDetails, { collectionId: collectionId })] }), collectionType !== 'ongoing' && (_jsx(Alert, { className: styles.unassignAlert, variant: INFO, children: t('your_collections.show.adhoc_warning') }))] }), _jsx(CollectionActions, { collectionId: collectionId, collectionType: collectionType, collectionEndDate: collectionEndDate })] }));
};
export default memo(MyCollectionWrapper);
